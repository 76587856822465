import React, {Component,useState  } from 'react'
import { NavLink } from 'react-router-dom'
import {baseAPIURL} from "../api/Access"

import  { useEffect  } from 'react';
import  axios  from 'axios'

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import {Typography,
  CardActionArea} from '@material-ui/core';
import Container from '@material-ui/core/Container';

import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'
import { compose } from 'redux'
import  firebase  from 'firebase'


import { makeStyles } from "@material-ui/core/styles";

import mainStyle from "../assets/mainStyle";
const useStyles = makeStyles((theme) => ({
    icon: {
      marginRight: theme.spacing(2),
    },
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
      marginTop: theme.spacing(4),
    },
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      paddingTop: '56.25%', // 16:9
    },
    cardContent: {
      flexGrow: 1,
    },
    footer: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6),
    },
  }));

const View = ({user,DataSets}) => {

    const classes = useStyles();



  
    const [list, setList] = React.useState(null);
    const [{loading,error}, setStatus] = React.useState({loading:true});
  
    //let navigate = useNavigate();
    
      const LoadData = async () => {
          setStatus({loading:true});
          setList(null)
          console.log("LoadData")


        const  idToken = firebase.auth().currentUser ? await firebase.auth().currentUser.getIdToken(): ""

      console.log(baseAPIURL)
          axios.post(baseAPIURL+'group/public',{},{"headers":{"Authorization": idToken}}).then(Result => {
              
              console.log(Result)
              if(Result.data.error)
              {
                  setStatus({error:true});
              }
              else if(Result.data.list)
              {
                  setList(Result.data.list)
                  setStatus({});
              }
              else
              {
                  setList([])
                  setStatus({error:true});
              }
              
          }).catch(err => {
              console.error("handleSubmit")
              console.error(err)
              setStatus({error:true});
              
          });
        
          
      };
  
  
  
    
    useEffect(() => {
      LoadData();
    }, []);

    



        return (
            <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="sm">
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
              Data
            </Typography>
            <Typography variant="h5" align="center" color="textSecondary" paragraph>
              Gather, store, sort your data from different devices and tools.
            </Typography>
            <div className={classes.heroButtons}>
              <Grid container spacing={2} justify="center">
                <Grid item>
                    {user ? <Button component={NavLink} to="/datasets" variant="contained" color="primary">
                    your Data Sets
                  </Button>:<Button component={NavLink} to="/login" variant="contained" color="primary">
                    Log in
                  </Button>}
                  
                </Grid>
                <Grid item>
                  <Button  component={Link} target="_blank" href="https://documents.spried.com" variant="outlined" color="primary">
                    Read Documents
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>


        
        <Container >

        <hr/>
<br/>
<br/>
          
        <Typography component="h4" variant="h4" color="textPrimary" gutterBottom>
              public data sets
            </Typography>

            
            <Typography variant="body"  color="textSecondary" paragraph>
              Public data sets that you can contribute to and use.
            </Typography>

            <br/>
<br/>
<br/>

        <Grid container className={classes.root} spacing={2}>
              {error ?
                "Error"
              :
              loading ?
                "Loading"
              :
                list && list.map((row, index) => {
                    return (
                      <Grid item lg={2} md={3} sm={6} xs={12}>
                        <Card className={classes.CardRoot}>
                          <CardActionArea  component={NavLink} to={"/datasets/"+row.data_id} >
                            
                              <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                  {row.name}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                  {row.details}
                                </Typography>
                              </CardContent>
                            </CardActionArea>


                            {/*<CardActions>
                              <Button size="small" color="primary">
                                Share
                              </Button>
                              <Button size="small" color="primary">
                                Learn More
                              </Button>
                              <Button size="small" color="primary">
                                Add Data
                              </Button>
                    </CardActions>*/}

                          </Card>
                        </Grid>
                      );
                    })
              }
                </Grid>
                
          </Container>
              </main>
        );
}








const mapStateToProps = (state) => {
  return {
      user:firebase.auth().currentUser
  }
}
export default connect(mapStateToProps)(View);