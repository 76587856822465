const mainStyle = {

  colouredButton: {
    backgroundColor:"#19A78E",
    fontSize: "16px;",
    color: "#fff;",
    fontWeight: "700;",
    textDecoration: "none",
    padding: "13px;",
    borderRadius: "50px;",
    "&:hover":{
        backgroundColor:"#374657",
    }
  },

  clearButton: {
    fontSize: "16px;",
    color: "#374657;",
    fontWeight: "700;",
    textDecoration: "none"
  },
  Center:{
    textAlign: "center;"
  }
};
export default mainStyle;
