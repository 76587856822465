import React, {Component,useState  } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import {baseAPIURL} from "../../api/Access"

import  { useEffect  } from 'react';
import  axios  from 'axios'

import {AppBar,FormControl,Select,InputLabel,MenuItem,Menu,Box,Checkbox,FormControlLabel,FormGroup} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import {Typography,
  CardActionArea} from '@material-ui/core';
import Container from '@material-ui/core/Container';
import MenuIcon from '@material-ui/icons/Menu';


import Page404 from '../Page404';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'
import { compose } from 'redux'
import  firebase  from 'firebase'


import { makeStyles } from "@material-ui/core/styles";

import mainStyle from "../../assets/mainStyle";
const useStyles = makeStyles((theme) => ({
    icon: {
      marginRight: theme.spacing(2),
    },
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
      marginTop: theme.spacing(4),
    },
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      paddingTop: '56.25%', // 16:9
    },
    cardContent: {
      flexGrow: 1,
    },
    footer: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6),
    },
  }));

const View = ({user,match}) => {

    const classes = useStyles();

    
  
    const [changePermistions, setChangePermistions] = React.useState(null);
    const [values, setValues] = React.useState({});
    const [Saving, setSaving] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [adduser, setadduser] = React.useState(false);
    const [adduserValues, setadduserValues] = React.useState({});
    const [openLeave, setLeave] = React.useState(false);
    
    const [limit, setLimit] = React.useState(30);
    
    const [users, setUsers] = React.useState([]);
    const [details, setDetails] = React.useState(null);
    const [list, setList] = React.useState(null);
    const [{loading,loadingList,error}, setStatus] = React.useState({loading:true});
    const [{LoadingUsers,ErrorUsers,openUsers}, setStatusUsers] = React.useState({});
    const history = useHistory()
  
    const [anchorEl, setAnchorEl] = React.useState(null);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

    //let navigate = useNavigate();
    
    const loadList = async () =>{
      
      setStatus({loadingList:true});

      console.log(firebase.auth().currentUser)
      firebase.auth().currentUser.getIdToken().then(function(idToken) {

      axios.post(baseAPIURL+'data',{"id":match.params.setId,"limit":limit},{"headers":{"Authorization": idToken}}).then(Result => {
                                  
        console.log(Result)
        
        setStatus({});
        if(Result.data.error)
        {
            //setStatus({error:true});
        }
        else if(Result.data.list)
        {
            setList(Result.data.list)
            //setStatus({});
        }
        else
        {
          setList([])
            //setStatus({error:true});
        }
        
    }).catch(err => {
        console.error("handleSubmit")
        console.error(err)
        setStatus({error:true});
        
    });

  })
    }






      const LoadData = async () => {

        if(!match || !match.params || !match.params.setId)
        {
          setStatus({error:true});
          return
        }

        setStatus({loading:true});
          setDetails(null)
          setList(null)
          console.log("LoadData")
  

          const  idToken = firebase.auth().currentUser ? await firebase.auth().currentUser.getIdToken(): ""

                axios.post(baseAPIURL+'group/get',{"id":match.params.setId},{"headers":{"Authorization": idToken}}).then(Result => {
                                  
              console.log(Result)
              if(Result.data.error)
              {
                  setStatus({error:true});
              }
              else if(Result.data)
              {
                  setDetails(Result.data)
                  setStatus({});

                  loadList();
                  


                    
              }
              else
              {
                setDetails({})
                  setStatus({error:true});
              }
              
          }).catch(err => {
              console.error("handleSubmit")
              console.error(err)
              setStatus({error:true});
              
          });
          
      };
  



      

      const LoadUsers =()=>{
        //{LoadingUsers,ErrorUsers}
        setStatusUsers({LoadingUsers:true});
        
        firebase.auth().currentUser.getIdToken().then(function(idToken) {
            console.log(baseAPIURL)
            axios.post(baseAPIURL+'permissions/list',{id:match.params.setId},{"headers":{"Authorization": idToken}}).then(Result => {
              console.log(Result)
              
            if(Result.data.error)
            {
              alert(Result.data.error)
              setStatusUsers({ErrorUsers:true});

            }
            else if(Result.data.list)
            {
              setStatusUsers({openUsers:true});
              setUsers(Result.data.list);
              
                //LoadData()
            }
            else
            {
              alert("Error")
              
              setStatusUsers({ErrorUsers:true});
            }
            
        }).catch(err => {
            console.error("handleSubmit")
            console.error(err)
            setStatusUsers({ErrorUsers:true});
            
        });
      });

      }

  
  

      const handleOpen =()=>{
        setValues({});
        setOpen(true)
      }

      const handleClose =()=>{
        setOpen(false)
        setAnchorEl(null);
        setLeave(false)
        setSaving(false);
        setStatusUsers({})
        setadduser(false)
        setChangePermistions(null)
      }

      const handleUpdate =()=>{

        var NewForm = {}
        NewForm.name = values.name? values.name: details.name ?  details.name :""
        NewForm.details = values.details? values.details: details.details ?  details.details :""
        NewForm.public = "public" in values ? values.public : "public" in details ? details.public  :true
        NewForm.id = match.params.setId


        if(!NewForm.name || NewForm.name==="")
        {
          alert("name is required.")
          return
        }
        console.log(NewForm)

        setSaving(true)
        setOpen(false)

        
        console.log(firebase.auth().currentUser)
        firebase.auth().currentUser.getIdToken().then(function(idToken) {
          console.log(baseAPIURL)
              axios.post(baseAPIURL+'group/update',NewForm,{"headers":{"Authorization": idToken}}).then(Result => {
                                
            console.log(Result)
            if(Result.data.error)
            {
              alert(Result.data.error)
              setSaving(false);
            }
            else if(Result.data.success)
            {
                LoadData()
                setSaving(false);
            }
            else
            {
              alert("Error")
              setSaving(false);
            }
            
        }).catch(err => {
            console.error("handleSubmit")
            console.error(err)
            setStatus({error:true});
            
        });
      });


      }


      const handleLeave = () => {
        setStatus({loading:true});

       console.log(firebase.auth().currentUser)
       firebase.auth().currentUser.getIdToken().then(function(idToken) {
         console.log(baseAPIURL)
             axios.post(baseAPIURL+'permissions/leave',{id:match.params.setId},{"headers":{"Authorization": idToken}}).then(Result => {
                               
           if(Result.data.error)
           {
             alert(Result.data.error)
             setStatus({});

           }
           else if(Result.data.success)
           {
            handleClose()
               LoadData()
           }
           else
           {
             alert("Error")
             setSaving(false);
           }
           
       }).catch(err => {
           console.error("handleSubmit")
           console.error(err)
           setStatus({error:true});
           
       });
     });
     }


      const handleJoin = () => {
         setStatus({loading:true});

        console.log(firebase.auth().currentUser)
        firebase.auth().currentUser.getIdToken().then(function(idToken) {
          console.log(baseAPIURL)
              axios.post(baseAPIURL+'permissions/join',{id:match.params.setId},{"headers":{"Authorization": idToken}}).then(Result => {
                                
            if(Result.data.error)
            {
              alert(Result.data.error)
              setStatus({});

            }
            else if(Result.data.success)
            {
                LoadData()
            }
            else
            {
              alert("Error")
              setSaving(false);
            }
            
        }).catch(err => {
            console.error("handleSubmit")
            console.error(err)
            setStatus({error:true});
            
        });
      });
      }






      const handleChangePermistions = () => {
        setStatusUsers({LoadingUsers:true});

       console.log(firebase.auth().currentUser)
       firebase.auth().currentUser.getIdToken().then(function(idToken) {
         console.log(baseAPIURL)
             axios.post(baseAPIURL+'permissions/add',{id:match.params.setId,permissions:changePermistions.permissions, user:changePermistions.user_id},{"headers":{"Authorization": idToken}}).then(Result => {
              console.log(Result)
     
              if(Result.data.error)
              {
                alert(Result.data.error)
              }
           handleClose()
           LoadUsers()
           
       }).catch(err => {
           console.error("handleSubmit")
           console.error(err)
           setStatus({error:true});
           
       });
     });
      }


      const handleAdduser = () => {
        //setadduserValues

        setStatusUsers({LoadingUsers:true});

       console.log(firebase.auth().currentUser)
       firebase.auth().currentUser.getIdToken().then(function(idToken) {
         console.log(baseAPIURL)
             axios.post(baseAPIURL+'permissions/add',{id:match.params.setId,permissions:adduserValues.permissions?adduserValues.permissions:1, email:adduserValues.email},{"headers":{"Authorization": idToken}}).then(Result => {
              console.log(Result)
     
              if(Result.data.error)
              {
                alert(Result.data.error)
              }
           handleClose()
           LoadUsers()
           
       }).catch(err => {
           console.error("handleSubmit")
           console.error(err)
           setStatus({error:true});
           
       });
     });
      }
      

    
    useEffect(() => {
      LoadData();
    }, []);

    

    if( error || !match || !match.params || !match.params.setId)
      return(<Page404/>)

      if(error)
      return "error"

      if(loading)
        return "Loading"


    return (
            <main>

        

        
              <Container>

              <Typography gutterBottom variant="h5" component="h2">{details.name}</Typography>
              <Typography gutterBottom variant="body" component="p">{details.data_id}</Typography>
              <Typography gutterBottom variant="body" component="p">created: {details.created}</Typography>
              <Typography gutterBottom variant="body" component="p">{details.public ? "public": "Private"}</Typography>
              <Typography gutterBottom variant="body" component="p">{details.details}</Typography>

<Box  sx={{ display: 'flex' }}>

              <Button component={NavLink} to={"/datasets/"+ match.params.setId+"/upload"}>upload</Button>
              {!user?<Button component={NavLink} to={"/login"} >join</Button>: !details.permissions || details.permissions == 0?<Button onClick={handleJoin}>join</Button>:null}

              <Box sx={{ flexGrow: 1 }}></Box>
              
              {details.permissions >= 1 ?
              <Button
        id="basic-button"
        aria-haspopup="true"
        onClick={handleMenuClick}
      >
        <MenuIcon/>
      </Button>
      :null}


      </Box>


      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {details.permissions >= 3 ? <MenuItem onClick={()=>{handleClose();handleOpen()}}>edit</MenuItem> : null}
        {details.permissions >= 3 ? <MenuItem onClick={()=>{handleClose();LoadUsers()}}>users</MenuItem> : null}
        <MenuItem onClick={()=>{handleClose();setLeave(true)}}>leave</MenuItem>


      </Menu>



<hr/>

                <Grid container className={classes.root} spacing={2}>
                  {
                    list &&  list.length>0 ? list.map((row, index) => {
                        return (
                          <Grid item lg={2} md={3} sm={6} xs={12}  spacing={2}>
                            <Card className={classes.CardRoot}  spacing={2}>
                              <CardActionArea  component={NavLink} to={"/datasets/"+match.params.setId+"/data/"+row.uuid} >
                                
                                  <CardContent>
                                    <Typography gutterBottom variant="h6" component="h3">{row.file_name}</Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">{row.content_type}</Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">{row.upload_date}</Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">{row.uuid}</Typography>
                                  </CardContent>
                                </CardActionArea>


                              </Card>
                            </Grid>
                          );
                        })
                        
                        :

                        <Box>
                        <Typography variant="h6"  
                        align="center"
                        justifyContent="center">
                           You have no data.
                        </Typography>
                        </Box>
                  }

                  {loadingList ? <Box>
                        <Typography variant="h6"  
                        align="center"
                        justifyContent="center">
                           Loading List
                        </Typography>
                        </Box>
                  : null}


                  {!loadingList && list &&  list.length>0 ?
                      <Grid item lg={12} md={12} sm={12} xs={12}  spacing={2}>
                        <Button onClick={()=>{setLimit(limit+30); loadList()}}>Load More</Button>
                      </Grid>
                    :
                      null
                  }
                </Grid>
                    
              </Container>









              
              <Dialog open={openLeave} onClose={handleClose}>
                <DialogTitle>Are you sure?</DialogTitle>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button onClick={handleLeave}>Leave</Button>
                </DialogActions>
              </Dialog>




              <Dialog open={Saving} >
                <DialogContent>
                  Loading
                </DialogContent>
              </Dialog>

              

              <Dialog open={openUsers} >
              <DialogTitle>Users</DialogTitle>
                <DialogContent>
                  <Box>
                    {users.map((item)=>
                      <Box>
                        <Box>{item.displayName ? item.displayName : ""} </Box>
                        <Box>{item.email ? item.email : item.user_id} </Box>
                        <Box>
                          { item.permissions == 1 
                            ? "View" 
                            : item.permissions == 2 
                            ? "details editor" 
                            : item.permissions == 3 
                            ? "editor" 
                            : item.permissions == 4 
                            ? "admin" 
                            : item.permissions == 5 
                            ? "owner" 
                            : null
                              /*1 - viewer
                              * 2 - details editor
                              * 3 - editor
                              * 4 - admin
                              * 5 - owner*/
                          } 
                          {details.permissions>=item.permissions ? <Button onClick={()=>setChangePermistions(item)}>Change</Button>:null}
                        </Box>
                        {/*setUsers(Result.data.list);*/}
                        <hr/>
                      </Box>
                    )}
                    
                  </Box>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>close</Button>
                <Button onClick={()=>{setadduserValues({});setadduser(true)}}>Add User</Button>
                </DialogActions>
              </Dialog>
              

{changePermistions ?
              <Dialog open={changePermistions?true:false} >
                <DialogTitle>Permissions User</DialogTitle>
                <DialogContent>
                  <p>Permistion for {changePermistions.displayName}</p>

                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Permission</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={changePermistions.permissions}
                      label="Permissions"
                      onChange={(event)=>{setChangePermistions({...changePermistions,permissions:event.target.value})}}
                    >
                      <MenuItem value={0}>remove</MenuItem>
                      <MenuItem value={1}>View</MenuItem>
                      <MenuItem value={2}>details editor</MenuItem>
                      <MenuItem value={3}>editor</MenuItem>
                      <MenuItem value={4}>admin</MenuItem>
                      {details.permissions>4 ? <MenuItem value={5}>owner</MenuItem>:null}

                    </Select>
                  </FormControl>
                  
                  
                </DialogContent>
                <DialogActions>
                  <Button onClick={()=>{setChangePermistions(null)}}>close</Button>
                  <Button onClick={handleChangePermistions}>save</Button>
                </DialogActions>
              </Dialog>
:null}
              

              <Dialog open={adduser} >
                <DialogTitle>Add User</DialogTitle>
                <DialogContent>
                  
                  
                  
                <TextField
                    autoFocus
                    margin="dense"
                    id="email"
                    type="email"
                    label="Email"
                    fullWidth
                    variant="filled"
                    value={adduserValues.email? adduserValues.email: ""}
                    onChange={(event)=>setadduserValues({...adduserValues, email:event.target.value})}
                  />

                  
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Permission</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={adduserValues.permissions ? adduserValues.permissions : 1}
                      label="Permissions"
                      onChange={(event)=>{setadduserValues({...adduserValues,permissions:event.target.value})}}
                    >
                      <MenuItem value={1}>View</MenuItem>
                      <MenuItem value={2}>details editor</MenuItem>
                      <MenuItem value={3}>editor</MenuItem>
                      <MenuItem value={4}>admin</MenuItem>
                      {details.permissions>4 ? <MenuItem value={5}>owner</MenuItem>:null}

                    </Select>
                  </FormControl>

                </DialogContent>
                <DialogActions>
                  <Button onClick={()=>{setadduser(false)}}>close</Button>
                  <Button onClick={handleAdduser}>save</Button>
                </DialogActions>
              </Dialog>


<Dialog open={LoadingUsers} >
  <DialogContent>
    Loading
  </DialogContent>
</Dialog>



<Dialog open={ErrorUsers} >
  <DialogContent>
    Error
  </DialogContent>
</Dialog>





              <Dialog open={open} >
                <DialogTitle>Edit dataset</DialogTitle>
                <DialogContent>
                  {/*<DialogContentText>
                  </DialogContentText>*/}
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Dataset Name"
                    fullWidth
                    variant="filled"
                    value={values.name? values.name: details.name ?  details.name :null}
                    onChange={(event)=>setValues({...values, name:event.target.value})}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="details"
                    label="Details"
                    fullWidth
                    variant="filled"
                    multiline
                    rows={3}
                    value={values.details? values.details: details.details ?  details.details :null}
                    onChange={(event)=>setValues({...values, details:event.target.value})}
                  />


                  <FormGroup>
                    <FormControlLabel control={<Checkbox  color="default" checked={"public" in values ? values.public : "public" in details ? details.public  :true} onChange={(event)=>setValues({...values, public:event.target.checked})} />} label="Public" />
                  </FormGroup>

                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button onClick={handleUpdate}>Save</Button>
                </DialogActions>
              </Dialog>

              


            </main>
        );
}








const mapStateToProps = (state) => {
  return {
      user:firebase.auth().currentUser
  }
}
export default connect(mapStateToProps)(View);