import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/analytics'
//import  Firebaseui  from 'firebaseui'

// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyBQac5PZqqfD1BO21iRLUASoemsJGBxBH0",
  authDomain: "spried-bb41e.firebaseapp.com",
  projectId: "spried-bb41e",
  storageBucket: "spried-documents",
  messagingSenderId: "559502217297",
  appId: "1:559502217297:web:3c4053d9c412ffd6c6a870",
  measurementId: "G-ZFRRB02E05"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.storage();


const db = firebase.firestore();
const auth = firebase.auth;

// eslint-disable-next-line no-restricted-globals
if(process.env.REACT_APP_ENV === "development" && process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "development")
{
  db.useEmulator('localhost', 8080);
  auth().useEmulator('http://localhost:9099/', { disableWarnings: true });
}

export default firebase;
export { db, auth };