import React, {Component,useState  } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import {baseAPIURL} from "../../api/Access"

import  { useEffect  } from 'react';
import  axios  from 'axios'

import {AppBar,Box,Checkbox,FormControlLabel,FormGroup} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import {Typography,
  CardActionArea} from '@material-ui/core';
import Container from '@material-ui/core/Container';



import Page404 from '../Page404';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'
import { compose } from 'redux'
import  firebase  from 'firebase'


import { makeStyles } from "@material-ui/core/styles";

import mainStyle from "../../assets/mainStyle";
const useStyles = makeStyles((theme) => ({
    icon: {
      marginRight: theme.spacing(2),
    },
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
      marginTop: theme.spacing(4),
    },
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      paddingTop: '56.25%', // 16:9
    },
    cardContent: {
      flexGrow: 1,
    },
    footer: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6),
    },
  }));

const View = ({user,DataSets}) => {

    const classes = useStyles();

  
    const [values, setValues] = React.useState({public:true});
    const [limit, setLimit] = React.useState(30);
    const [Saving, setSaving] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [list, setList] = React.useState(null);
    const [{loading,loadingList,error}, setStatus] = React.useState({loading:true});
    const history = useHistory()
  
    //let navigate = useNavigate();
    


      const LoadData = () => {
          setStatus({loading:true});
          //setList(null)
          console.log("LoadData")
  
          console.log(firebase.auth().currentUser)
          firebase.auth().currentUser.getIdToken().then(function(idToken) {
            console.log(baseAPIURL)
                axios.post(baseAPIURL+'group/list',{"limit":limit},{"headers":{"Authorization": idToken}}).then(Result => {
                                  
              console.log(Result)
              if(Result.data.error)
              {
                  setStatus({error:true});
              }
              else if(Result.data.list)
              {
                  setList(Result.data.list)
                  setStatus({});
              }
              else
              {
                  setList([])
                  setStatus({error:true});
              }
              
          }).catch(err => {
              console.error("handleSubmit")
              console.error(err)
              setStatus({error:true});
              
          });
        });
          
      };
  
  
  

      const handleOpen =()=>{
        setValues({public:true});
        setOpen(true)
      }

      const handleClose =()=>{
        setOpen(false)
      }

      const handleAdd =()=>{
        if(!values.name || values.name==="")
        {
          alert("name is required.")
          return
        }

        setSaving(true)
        setOpen(false)

        
        console.log(firebase.auth().currentUser)
        firebase.auth().currentUser.getIdToken().then(function(idToken) {
          console.log(baseAPIURL)
              axios.post(baseAPIURL+'group/create',values,{"headers":{"Authorization": idToken}}).then(Result => {
                                
            console.log(Result)
            if(Result.data.error)
            {
              alert(Result.data.error)
              setSaving(false);
            }
            else if(Result.data.success && Result.data.id)
            {
               history.push("/datasets/"+ Result.data.id);
                setSaving(false);
            }
            else
            {
              alert(Result.data.error)
              setSaving(false);
            }
            
        }).catch(err => {
            console.error("handleSubmit")
            console.error(err)
            setStatus({error:true});
            
        });
      });


      }


    
    useEffect(() => {
      LoadData();
    }, []);

    

    if(!user)
      return(<Page404/>)


    return (
            <main>

<Container>
  <br/>
              <Button  variant="contained" onClick={handleOpen}>Add</Button>
        

              <br/>
  <br/>

              <Dialog open={Saving} >
                <DialogContent>
                  Loading
                </DialogContent>
              </Dialog>





              <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add new dataset</DialogTitle>
                <DialogContent>
                  {/*<DialogContentText>
                  </DialogContentText>*/}
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Dataset Name"
                    fullWidth
                    variant="filled"
                    //value={values.name? values.name: null}
                    onChange={(event)=>setValues({...values, name:event.target.value})}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="details"
                    label="Details"
                    fullWidth
                    variant="filled"
                    multiline
                    rows={3}
                    //value={values.details? values.details: null}
                    onChange={(event)=>setValues({...values, details:event.target.value})}
                  />


                  <FormGroup>
                    <FormControlLabel control={<Checkbox  color="default" checked={values.public ? true:false} onChange={(event)=>setValues({...values, public:event.target.checked})} />} label="Public" />
                  </FormGroup>

                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button onClick={handleAdd}>Add</Button>
                </DialogActions>
              </Dialog>

              

                <Grid container className={classes.root} spacing={2}>
                  {error ?
                    "Error"
                  :
                    list &&  list.length>0 ? list.map((row, index) => {
                        return (
                          <Grid item lg={2} md={3} sm={6} xs={12}  spacing={2}>
                            <Card className={classes.CardRoot}  spacing={2}>
                              <CardActionArea  component={NavLink} to={"/datasets/"+row.data_id} >
                                
                                  <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                      {row.name}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                    {row.details}
                        </Typography>
                                  </CardContent>
                                </CardActionArea>


                                {/*<CardActions>
                                  <Button size="small" color="primary">
                                    Share
                                  </Button>
                                  <Button size="small" color="primary">
                                    Learn More
                                  </Button>
                                  <Button size="small" color="primary">
                                    Add Data
                                  </Button>
                        </CardActions>*/}

                              </Card>
                            </Grid>
                          );
                        })
                        :
                        !loading?
                        <Box>
                        <Typography variant="h6"  
                        align="center"
                        justifyContent="center">
                           You have no datasets.
                        </Typography>
                        </Box>
                        :null
                  }


            
{loading ? <Box>
                        <Typography variant="h6"  
                        align="center"
                        justifyContent="center">
                           Loading List
                        </Typography>
                        </Box>
                  : null}


                  {!loadingList && list &&  list.length>0 ?
                      <Grid item lg={12} md={12} sm={12} xs={12}  spacing={2}>
                        <Button onClick={()=>{setLimit(limit+30); LoadData()}}>Load More</Button>
                      </Grid>
                    :
                      null
                  }
                    </Grid>
                    
              </Container>
            </main>
        );
}








const mapStateToProps = (state) => {
  return {
      user:firebase.auth().currentUser
  }
}
export default connect(mapStateToProps)(View);