import React from 'react'
import { NavLink } from 'react-router-dom'
import Container from '@material-ui/core/Container';

import { makeStyles } from "@material-ui/core/styles";
import mainStyle from "../assets/mainStyle";
const useStyles = makeStyles( {
    ... mainStyle,
    root:{
        textAlign: "center;",
        paddingTop: "20px;",
        marginBottom: "100px;",
    }
});

const Maintenance = () => {
    const classes = useStyles();

    return (
            <Container className={classes.root}>
                <h1><strong>Under Maintenance.</strong></h1>
            </Container>
        )
}
export default Maintenance
