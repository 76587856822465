const initState ={
    isLoaded: false,
    //connected: false,
    //found: false,
    //notifications: [],
    notificationsCount: 0,
    error: null
}

const profileReducer = (state = initState, action) => {
    switch(action.type) {
        case "PROFILE_LOADED": 
        
            return {
                ...state,
                isLoaded: true,
                details:action.Profile,
            }
        case "PROFILE_ERROR":
            console.error("PROFILE_ERROR")
            console.error(action.error)
            return {
                ...state,
                isLoaded: true,
            }
        case "PROFILE_NOTIFICATIONS":
            return {
                ...state,
                notifications: action.notifications,
            }
        case "PROFILE_NOTIFICATIONSCOUNT":
            return {
                ...state,
                notificationsCount: action.count,
            }
        /*case "PROFILE_FOUND":
            return {
                ...state,
                found: true,
            }
        case "PROFILE_NOTFOUND":
            return {
                ...state,
                found: false,
            }*/
        default: 
            return state;
    }

    return state;
}

export default profileReducer;