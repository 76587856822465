import React, {Component,useState,useCallback} from 'react'
import {useDropzone} from 'react-dropzone'

import { NavLink, useHistory } from 'react-router-dom'
import {baseAPIURL,uploadAPIURL} from "../../api/Access"

import  { useEffect  } from 'react';
import  axios  from 'axios'

import {AppBar,Box,Checkbox,FormControlLabel,FormGroup} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import {Typography,
  CardActionArea} from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { Buffer } from "buffer";



import Page404 from '../Page404';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'
import { compose } from 'redux'
import  firebase  from 'firebase'


import { makeStyles } from "@material-ui/core/styles";

import mainStyle from "../../assets/mainStyle";
const useStyles = makeStyles((theme) => ({
    icon: {
      marginRight: theme.spacing(2),
    },
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
      marginTop: theme.spacing(4),
    },
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      paddingTop: '56.25%', // 16:9
    },
    cardContent: {
      flexGrow: 1,
    },
    footer: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6),
    },
  }));

const View = ({user,match}) => {

    const classes = useStyles();

  
    const [{loading,error}, setStatus] = React.useState({});
    const [uploadCount, setUploadCount] = React.useState(0);
    const history = useHistory()
  
    const onDrop = useCallback(async (acceptedFiles) => {


      await acceptedFiles.forEach((file) => {
        
      console.log(file)

        const reader = new FileReader()

        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = () => {
         
        // Do whatever you want with the file contents
        console.log(typeof reader.result )
        console.log( reader.result )
          var binaryStr =  reader.result

          console.log(file.type)
          console.log(binaryStr)
          
          const header = {"headers":{"Content-Type": file.type?file.type:'text/plain',/*"X-API-Key": match.params.setId,*/"File-Name": file.name}}
          //const header = {"headers":{"X-API-Key": match.params.setId}}
          console.log(uploadAPIURL+match.params.setId)
          setUploadCount(uploadCount+1)
          axios.post(uploadAPIURL+match.params.setId,binaryStr,header).then(Result => {
             
          setUploadCount(uploadCount-1)                       
            console.log(Result)
            if(Result.data.error)
            {
                //setStatus({error:true});
            }
            else if(Result.data.id)
            {
                //setStatus({});
            }
            else
            {
                //setStatus({error:true});
            }
            
          }).catch(err => {
              setUploadCount(uploadCount-1)   
              console.error("handleSubmit")
              console.error(err)
              //setStatus({error:true});
              
          });

        }
        reader.readAsArrayBuffer(file)
        //reader.readAsBinaryString(file)
        
      })
  
      
    }, [])
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})



    //let navigate = useNavigate();
    
  
    useEffect(() => {

      /*const header = {"headers":{/*"Content-Type": 'text/plain',/*"File-Name": "file.name"* /}}
      //const header = {"headers":{"X-API-Key": match.params.setId}}
      axios.post(uploadAPIURL+match.params.setId,"binaryStr",header)
      */

      /*
var requestOptions = {
  method: 'POST',
  body: "file",
  //redirect: 'follow'
};

fetch("http://localhost:3020/1a66d0e6-a917-11ed-94e6-482ae33e6b11", requestOptions)
  .then(response => response.text())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));


      
var config = {
  method: 'post',
  url: uploadAPIURL+match.params.setId,
  data : "data",
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    "Access-Control-Allow-Origin": "*",
}

};
console.log(config)

axios(config).then(Result => {
                                    
            console.log(Result)
            if(Result.data.error)
            {
                //setStatus({error:true});
            }
            else if(Result.data.id)
            {
                //setStatus({});
            }
            else
            {
                //setStatus({error:true});
            }
            
          }).catch(err => {
              console.error("handleSubmit")
              console.error(err)
              //setStatus({error:true});
              
          });*/
    }, []);
    

    

    if(!user || error || !match || !match.params || !match.params.setId)
      return(<Page404/>)

      if(error)
      return "error"

      if(loading)
        return "Loading"



       

    return (
            <main>


<Dialog open={uploadCount>0} >
                <DialogContent>
                  Loading<br/>
                  uploading file
                </DialogContent>
              </Dialog>



              <Container align="center">
                <Box align="center" sx={{mt:2,pt:"30px",pb:"30px", border: "1px solid #444", with:"100%", textAlign:"center"}} {...getRootProps()}>
                  <input {...getInputProps()} />
                  {
                    isDragActive ? <p>Drop files here<br/> 20MB max</p> :<p>Drop files here or click here to select files<br/> 20MB max</p> 
                  }
                </Box>
              </Container>
            </main>
        );
}








const mapStateToProps = (state) => {
  return {
      user:firebase.auth().currentUser
  }
}
export default connect(mapStateToProps)(View);