import axios from 'axios'
import { getFirebase } from 'react-redux-firebase'


/*
var baseAPIURL = 'http://localhost:3010/';
var uploadAPIURL = 'http://localhost:3020/';
var downloadAPIURL = 'http://localhost:3030/';
*/

var baseAPIURL = 'https://api.data.spried.com/';
var uploadAPIURL = 'https://upload.data.spried.com/';
var downloadAPIURL = 'https://download.data.spried.com/';

export { baseAPIURL,uploadAPIURL,downloadAPIURL };