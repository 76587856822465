import React, {Component,useState  } from 'react'
import { NavLink } from 'react-router-dom'
import {baseAPIURL} from "../api/Access"

import  { useEffect  } from 'react';
import  axios  from 'axios'

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import {Typography,
  CardActionArea} from '@material-ui/core';
import Container from '@material-ui/core/Container';

import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'
import { compose } from 'redux'
import  firebase  from 'firebase'


import { makeStyles } from "@material-ui/core/styles";

import mainStyle from "../assets/mainStyle";
const useStyles = makeStyles((theme) => ({
    icon: {
      marginRight: theme.spacing(2),
    },
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
      marginTop: theme.spacing(4),
    },
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      paddingTop: '56.25%', // 16:9
    },
    cardContent: {
      flexGrow: 1,
    },
    footer: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6),
    },
  }));

const View = ({user,DataSets}) => {

    const classes = useStyles();



  
    const [list, setList] = React.useState(null);
    const [limit, setLimit] = React.useState(30);
    const [{loading,error}, setStatus] = React.useState({loading:true});
  
    //let navigate = useNavigate();
    
      const LoadData = async () => {
        setStatus({loading:true});
          setList(null)
          console.log("LoadData")
  
          
          const  idToken = firebase.auth().currentUser ? (await firebase.auth().currentUser.getIdToken()): ""

          console.log(baseAPIURL)
                axios.post(baseAPIURL+'group/public',{limit:limit},{"headers":{"Authorization": idToken}}).then(Result => {
                    
              
              console.log(Result)
              if(Result.data.error)
              {
                  setStatus({error:true});
              }
              else if(Result.data.list)
              {
                  setList(Result.data.list)
                  setStatus({});
              }
              else
              {
                  setList([])
                  setStatus({error:true});
              }
              
          }).catch(err => {
              console.error("handleSubmit")
              console.error(err)
              setStatus({error:true});
              
          });
          
      };
  
  
  
    
    useEffect(() => {
      LoadData();
    }, []);

    



        return (
            <main>

<Container>

<br/>
<br/>
          
        <Typography component="h4" variant="h4" color="textPrimary" gutterBottom>
              public data sets
            </Typography>

            
            <Typography variant="body"  color="textSecondary" paragraph>
              Public data sets that you can contribute to and use.
            </Typography>

            <br/>
            <br/>
            <br/>
            
        
                <Grid container className={classes.root} spacing={2}>
                  {error ?
                    "Error"
                  :
                  loading ?
                    "Loading"
                  :
                    list && list.map((row, index) => {
                        return (
                          <Grid item lg={2} md={3} sm={6} xs={12}  spacing={2}>
                            <Card className={classes.CardRoot}  spacing={2}>
                              <CardActionArea  component={NavLink} to={"/datasets/"+row.data_id} >
                                
                                  <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                      {row.name}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                    {row.details}
                        </Typography>
                                  </CardContent>
                                </CardActionArea>


                                {/*<CardActions>
                                  <Button size="small" color="primary">
                                    Share
                                  </Button>
                                  <Button size="small" color="primary">
                                    Learn More
                                  </Button>
                                  <Button size="small" color="primary">
                                    Add Data
                                  </Button>
                        </CardActions>*/}

                              </Card>
                            </Grid>
                          );
                        })
                  }

<Grid item lg={12} md={12} sm={12} xs={12}  spacing={2}>
<Button onClick={()=>{setLimit(limit+30); LoadData()}}>Load More</Button>
</Grid>
                    </Grid>
                    
              </Container>
            </main>
        );
}








const mapStateToProps = (state) => {
  return {
      user:firebase.auth().currentUser
  }
}
export default connect(mapStateToProps)(View);