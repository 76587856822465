import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import  firebase  from 'firebase'
import SiteLogo from "../other/SiteLogo"

import { makeStyles } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MemoryIcon from '@material-ui/icons/Memory';
import MenuIcon from '@material-ui/icons/Menu'; 

const useStyles = makeStyles({

    footer: {
      
        padding: "20px 20px 5px 20px;",
       /* width: "100%;",
  position: "absolute",
  bottom: "0;",
  left: "0;"
      /*backgroundColor: "#19A78E",
      color: "#fff",
      paddingTop:"50px",
      paddingBottom:"20px",
      
      backgroundImage:"url('/images/footer-background.svg');",
      backgroundRepeat: "no-repeat;",
      backgroundPosition: "center;",
      backgroundSize:"cover;",*/
    },
    copyright:
    {
      fontSize:"10px"
      /*borderTop:"1px solid #fff",
      padding:" 20px 0",
      color: "#fff",
      */
    },
  });


const Footer = (props) => {

    const classes = useStyles();

    return (
        <footer className={classes.footer}>
          <div className={classes.copyright}>
              Copyright © 2020 <NavLink to="https://spried.com/">spried.com</NavLink>. All rights reserved.
          </div>
        </footer>
    )
}

export default (Footer)