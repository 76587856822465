import React, {Component,useState  } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import {baseAPIURL} from "../../api/Access"

import  { useEffect  } from 'react';
import  axios  from 'axios'

import {AppBar,Box,Checkbox,FormControlLabel,FormGroup} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import {Typography,
  CardActionArea} from '@material-ui/core';
import Container from '@material-ui/core/Container';



import Page404 from '../Page404';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'
import { compose } from 'redux'
import  firebase  from 'firebase'


import { makeStyles } from "@material-ui/core/styles";

import mainStyle from "../../assets/mainStyle";
const useStyles = makeStyles((theme) => ({
    icon: {
      marginRight: theme.spacing(2),
    },
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
      marginTop: theme.spacing(4),
    },
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      paddingTop: '56.25%', // 16:9
    },
    cardContent: {
      flexGrow: 1,
    },
    footer: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6),
    },
  }));

const View = ({user,DataSets}) => {

    const classes = useStyles();


    const [values, setValues] = React.useState({public:true});
    const [Saving, setSaving] = React.useState(false);
    const history = useHistory()
  

      const handleAdd =()=>{
        if(!values.name || values.name==="")
        {
          alert("name is required.")
          return
        }

        setSaving(true)

        
        console.log(firebase.auth().currentUser)
        firebase.auth().currentUser.getIdToken().then(function(idToken) {
          console.log(baseAPIURL)
              axios.post(baseAPIURL+'group/create',values,{"headers":{"Authorization": idToken}}).then(Result => {
                                
            console.log(Result)
            if(Result.data.error)
            {
              alert(Result.data.error)
              setSaving(false);
            }
            else if(Result.data.success && Result.data.id)
            {
               history.push("/dataset/"+ Result.data.id);
                setSaving(false);
            }
            else
            {
              alert(Result.data.error)
              setSaving(false);
            }
            
        }).catch(err => {
              alert("Error")
            console.error("handleSubmit")
            console.error(err)            
        });
      });


      }


    

    if(!user)
      return(<Page404/>)


    return (
            <main>

              <Dialog open={Saving} >
                <DialogContent>
                  Loading
                </DialogContent>
              </Dialog>



              

              <Container>
              <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Dataset Name"
                    fullWidth
                    variant="filled"
                    //value={values.name? values.name: null}
                    onChange={(event)=>setValues({...values, name:event.target.value})}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="details"
                    label="Details"
                    fullWidth
                    variant="filled"
                    multiline
                    rows={3}
                    //value={values.details? values.details: null}
                    onChange={(event)=>setValues({...values, details:event.target.value})}
                  />


                  <FormGroup>
                    <FormControlLabel control={<Checkbox  color="default" checked={values.public ? true:false} onChange={(event)=>setValues({...values, public:event.target.checked})} />} label="Public" />
                  </FormGroup>

                  <Button onClick={handleAdd}>Add</Button>

              </Container>
            </main>
        );
}








const mapStateToProps = (state) => {
  return {
      user:firebase.auth().currentUser
  }
}
export default connect(mapStateToProps)(View);