import React from 'react';

import { BrowserRouter, Route, Switch } from 'react-router-dom'

import Header from './components/Header/Header'
import Footer from './components/footer/Footer'
import Page404 from './views/Page404' 
import Maintenance from './views/Maintenance' 


import Home from './views/Home'
import Public from './views/Public'

import Login from './views/auth/Login'
import Logout from './views/auth/Logout'
import DataList from './views/DataSets/List'


//import AddDevice from './views/data/Add'
import View from './views/DataSets/View'
import Data from './views/DataSets/Data'
import Upload from './views/DataSets/Upload'

import AddDataSet from './views/DataSets/Add'


function App() {
  return (
    <BrowserRouter>
      <div className="App">
          <Header />
          <Switch>

            <Route exact path='/' component={Home} />
            <Route exact path='/logout' component={Logout}/>
            <Route exact path='/login' component={Login}/>
            
            <Route exact path='/public' component={Public} />

            <Route exact path='/add' component={AddDataSet} />
            
            <Route exact path='/datasets' component={DataList} />
            <Route exact path='/datasets/:setId' component={View} />
            <Route exact path='/datasets/:setId/data/:dataId' component={Data} />
            <Route exact path='/datasets/:setId/upload' component={Upload} />


            <Route component={Page404}  status={404}/>

          </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
