import React, {Component,useState  } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import {baseAPIURL,downloadAPIURL} from "../../api/Access"
import fileDownload from 'js-file-download';
import  { useEffect  } from 'react';
import  axios  from 'axios'

import {AppBar,Box,Checkbox,FormControlLabel,FormGroup} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import {Typography,
  CardActionArea} from '@material-ui/core';
import Container from '@material-ui/core/Container';



import Page404 from '../Page404';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'
import { compose } from 'redux'
import  firebase  from 'firebase'


import { makeStyles } from "@material-ui/core/styles";

import mainStyle from "../../assets/mainStyle";
const useStyles = makeStyles((theme) => ({
    icon: {
      marginRight: theme.spacing(2),
    },
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
      marginTop: theme.spacing(4),
    },
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      paddingTop: '56.25%', // 16:9
    },
    cardContent: {
      flexGrow: 1,
    },
    footer: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6),
    },
  }));

const View = ({user,match}) => {

    const classes = useStyles();

  
    const [values, setValues] = React.useState({"id":match.params.dataId? match.params.dataId:null});
    const [Saving, setSaving] = React.useState(false);
    const [{openAdd,openDelete,openDetails,openDetailsDelete,openDetailsEdit}, setOpen] = React.useState(false);

    const [limit, setLimit] = React.useState(30);
    const [{Dname,Ddetails,Duuid}, setDetailSelected] = React.useState({});
    const [DValues, setDValues] = React.useState({});

    const [details, setDetails] = React.useState(null);
    const [list, setList] = React.useState(null);
    const [{loading,loadingList,error}, setStatus] = React.useState({loading:true});
    const [DownloadLoading, setDownloadLoading] = React.useState(false);
    const history = useHistory()
  
    
    //let navigate = useNavigate();
    
    const loadList = async () =>{
      
      setStatus({loadingList:true});
      firebase.auth().currentUser.getIdToken().then(function(idToken) {
        axios.post(baseAPIURL+'data/details',{"id":match.params.dataId,"limit":limit},{"headers":{"Authorization": idToken}}).then(Result => {
                                    
          console.log(Result)
          if(Result.data.error)
          {
              //setStatus({error:true});
          }
          else if(Result.data.list)
          {
              setList(Result.data.list)
          }
          else
          {
            setList([])
              //setStatus({error:true});
          }
          
          setStatus({});
          
      }).catch(err => {
          console.error("handleSubmit")
          console.error(err)
          setStatus({error:true});
          
      });

      })
    }




      const LoadData = () => {

        if(!match || !match.params || !match.params.dataId)
        {
          setStatus({error:true});
          return
        }

        setStatus({loading:true});
        setOpen({})
          setDetails(null)
          setList(null)
          console.log("LoadData")
  
          console.log(firebase.auth().currentUser)
          firebase.auth().currentUser.getIdToken().then(function(idToken) {
                axios.post(baseAPIURL+'data/get',{"id":match.params.dataId},{"headers":{"Authorization": idToken}}).then(Result => {
                                  
              console.log(Result)
              if(Result.data.error)
              {
                  setStatus({error:true});
              }
              else if(Result.data)
              {
                  setDetails(Result.data)
                  setStatus({});   
                  
                  
                  loadList();
                  

                  
              }
              else
              {
                setDetails({})
                  setStatus({error:true});
              }
              
          }).catch(err => {
              console.error("handleSubmit")
              console.error(err)
              setStatus({error:true});
              
          });
        });
          
      };
  
  
  

      const handleDelete =()=>{
        
        if(details.permissions<2 || !match || !match.params || !match.params.dataId)
          {
            setStatus({error:true});
            return
          }
          
          setOpen({openDetailsDelete:true})
          setStatus({loading:true});
            setDetails(null)
            setList(null)
            console.log("LoadData")
    
            console.log(firebase.auth().currentUser)
            firebase.auth().currentUser.getIdToken().then(function(idToken) {
                  axios.post(baseAPIURL+'data/remove',{"id":match.params.dataId},{"headers":{"Authorization": idToken}}).then(Result => {
                                    
                console.log(Result)
                if(Result.data.error)
                {
                    setStatus({error:true});
                }
                else if(Result.data.success)
                {
                  history.push("/datasets/"+ match.params.setId);                
                }
                else
                {
                  setDetails({})
                    setStatus({error:true});
                }
                
            }).catch(err => {
                console.error("handleSubmit")
                console.error(err)
                setStatus({error:true});
                
            });
          });
      }


      
    const handleDeleteDetail =async ()=>{
      if(details.permissions<2 )
      return

        setStatus({loading:true});
         // setDetails(null)
          //setList(null)
          console.log("LoadData")
  
          console.log(firebase.auth().currentUser)
          firebase.auth().currentUser.getIdToken().then(function(idToken) {
            axios.post(baseAPIURL+'data/details/remove',{"id":Duuid},{"headers":{"Authorization": idToken}}).then(Result => {
                                  
              console.log(Result)
              if(Result.data.error)
              {
                  //setStatus({error:true});
              }
              else if(Result.data.success)
              {
                setOpen({})
                LoadData()
              }
              else
              {
                //setDetails({})
                  //setStatus({error:true});
              }
              
          }).catch(err => {
              console.error("handleSubmit")
              console.error(err)
              setStatus({error:true});
              
          });
        });
    }

    
    

    const handleEditDetail =()=>{
      setDValues({})
      setOpen({openDetailsEdit:true})
    }



    const handleClose =()=>{
      setOpen({})
      //setDetails({})
    }
    
    
    const handleSave =()=>{
      
      if(details.permissions<2 )
      return
      
      
      if(!(DValues.name || Dname) || !(DValues.details || Ddetails))
      {
        alert("name and details required")
        return
      }

      setStatus({loading:true});

      const data = {...DValues, id:Duuid}
      firebase.auth().currentUser.getIdToken().then(function(idToken) {
            axios.post(baseAPIURL+'data/details/update',data,{"headers":{"Authorization": idToken}}).then(Result => {
                               
          console.log(Result)
          if(Result.data.error)
          {
              setStatus({error:true});
          }
          else if(Result.data.success)
          {
            //setValues({"id":match.params.dataId? match.params.dataId:null})   
            setOpen({})    
            LoadData();        
          }
          else
          {
              setStatus({error:true});
          }
          
      }).catch(err => {
          console.error("handleSubmit")
          console.error(err)
          setStatus({error:true});
          
      });
    });
    }







    const handleAdd =()=>{
      
      if(details.permissions<2 )
      return
      
      if(!values.name || !values.details)
      {
        alert("name and details required")
        return
      }

      setStatus({loading:true});

      console.log(firebase.auth().currentUser)
      firebase.auth().currentUser.getIdToken().then(function(idToken) {
            axios.post(baseAPIURL+'data/details/create',values,{"headers":{"Authorization": idToken}}).then(Result => {
                               
          console.log(Result)
          if(Result.data.error)
          {
              setStatus({error:true});
          }
          else if(Result.data.success)
          {
            setValues({"id":match.params.dataId? match.params.dataId:null})   
            setOpen({})    
            LoadData();        
          }
          else
          {
              setStatus({error:true});
          }
          
      }).catch(err => {
          console.error("handleSubmit")
          console.error(err)
          setStatus({error:true});
          
      });
    });
    }




    const downloadFile = async () =>{
      setDownloadLoading(true)
      const url = downloadAPIURL+details.uuid
      const filename =  details.file_name?details.file_name:details.uuid+".data"

      firebase.auth().currentUser.getIdToken().then(async function(idToken) {

        const config = {
          method: 'get',
          url: url,
          responseType: 'blob',
          data: "",
          headers:{"Access-Control-Allow-Origin":"*","Authorization": idToken}
        }

        axios(config).then(res => {
          
          console.log("res.data")
          console.log(res.data)
          fileDownload(res.data, filename);
          setDownloadLoading(false)
        });
        
      });

      
    }
    

    
    const handleJoin = () => {
      setStatus({loading:true});

     console.log(firebase.auth().currentUser)
     firebase.auth().currentUser.getIdToken().then(function(idToken) {
       console.log(baseAPIURL)
           axios.post(baseAPIURL+'permissions/join',{id:match.params.setId},{"headers":{"Authorization": idToken}}).then(Result => {
                             
         if(Result.data.error)
         {
           alert(Result.data.error)
           setStatus({});

         }
         else if(Result.data.success)
         {
             LoadData()
         }
         else
         {
           alert("Error")
           setSaving(false);
         }
         
     }).catch(err => {
         console.error("handleSubmit")
         console.error(err)
         setStatus({error:true});
         
     });
   });
   }



    
    useEffect(() => {
      LoadData();
    }, []);

    

    if(!user || error || !match || !match.params || !match.params.dataId)
      return(<Page404/>)

      if(error)
      return "error"

      if(loading)
        return "Loading"


    return (
            <main>

        

        
              <Container>

              <Typography gutterBottom variant="h5" component="h2">{details.file_name}</Typography>
              <Typography gutterBottom variant="body" component="p">{details.uuid}</Typography>
              <Typography gutterBottom variant="body" component="p">{details.upload_date}</Typography>

              {details.permissions >= 3 ? <Button onClick={()=>setOpen({openDelete:true})}>delete</Button> : null}
              {details.permissions >= 1 ? <Button onClick={downloadFile} /*download target="_blank" href={downloadAPIURL+details.uuid}*/ >Download</Button> : null}
              {!user?<Button component={NavLink} to={"/login"} >join</Button>: !details.permissions || details.permissions == 0?<Button onClick={handleJoin}>join</Button>:null}


<hr/>
{details.permissions >= 2 ? <Button onClick={()=>setOpen({openAdd:true})}>Add</Button> : null}

                <Grid container className={classes.root} spacing={2}>
                  {
                    list &&  list.length>0 ? list.map((row, index) => {
                        return (
                          <Grid item lg={2} md={3} sm={6} xs={12}  spacing={2}>
                            <Card className={classes.CardRoot}  spacing={2}>
                              <CardActionArea  onClick={()=>{setDetailSelected({Dname:row.name,Ddetails:row.details,Duuid:row.uuid});setOpen({openDetails:true})}} >
                                
                                  <CardContent>
                                    <Typography variant="body2" color="textSecondary" component="p">{row.name}</Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">{row.details}</Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">{row.uuid}</Typography>
                                  </CardContent>
                                </CardActionArea>


                              </Card>
                            </Grid>
                          );
                        })
                        :

                        <Box>
                        <Typography variant="h6"  
                        align="center"
                        justifyContent="center">
                           You have no data.
                        </Typography>
                        </Box>
                  }


                  
                  
{loadingList ? <Box>
                        <Typography variant="h6"  
                        align="center"
                        justifyContent="center">
                           Loading List
                        </Typography>
                        </Box>
                  : null}


                  {!loadingList && list &&  list.length>0 ?
                      <Grid item lg={12} md={12} sm={12} xs={12}  spacing={2}>
                        <Button onClick={()=>{setLimit(limit+30); loadList()}}>Load More</Button>
                      </Grid>
                    :
                      null
                  }


                    </Grid>
                    
              </Container>








              <Dialog open={Saving || DownloadLoading ? true: false} >
                <DialogContent>
                  Loading
                </DialogContent>
              </Dialog>





              <Dialog open={openDetails} onClose={handleClose}>
                <DialogTitle>detail</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {Dname}
                    <br/>
                    {Ddetails}
                  </DialogContentText>

                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>close</Button>
                  
                  {details.permissions >= 2 ?<Button onClick={handleEditDetail}>edit</Button>:null}
                  {details.permissions >= 2 ?<Button onClick={()=>setOpen({openDetailsDelete:true})}>delete</Button>:null}
                </DialogActions>
              </Dialog>




              <Dialog open={openAdd} onClose={handleClose}>
                <DialogTitle>Add new detail</DialogTitle>
                <DialogContent>
                  {/*<DialogContentText>
                  </DialogContentText>*/}
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="name"
                    fullWidth
                    variant="filled"
                    value={values.name? values.name: ""}
                    onChange={(event)=>setValues({...values, name:event.target.value})}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="details"
                    label="Details"
                    fullWidth
                    variant="filled"
                    multiline
                    rows={3}
                    value={values.details? values.details: ""}
                    onChange={(event)=>setValues({...values, details:event.target.value})}
                  />

                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button onClick={handleAdd}>Save</Button>
                </DialogActions>
              </Dialog>







              
              <Dialog open={openDetailsEdit}>
                <DialogTitle>Edit detail</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="name"
                    fullWidth
                    variant="filled"
                    value={DValues.name?DValues.name:Dname? Dname: ""}
                    onChange={(event)=>setDValues({...DValues, name:event.target.value})}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="details"
                    label="Details"
                    fullWidth
                    variant="filled"
                    multiline
                    rows={3}
                    value={DValues.details?DValues.details:Ddetails? Ddetails: ""}
                    onChange={(event)=>setDValues({...DValues, details:event.target.value})}
                  />

                  

                </DialogContent>
                <DialogActions>
                  <Button onClick={()=>setOpen({openDetails:true})}>Cancel</Button>
                  <Button onClick={handleSave}>Save</Button>
                </DialogActions>
              </Dialog>





              
              <Dialog open={openDelete} onClose={handleClose}>
                <DialogTitle>Are you sure?</DialogTitle>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button onClick={handleDelete}>Delete</Button>
                </DialogActions>
              </Dialog>


              <Dialog open={openDetailsDelete} >
                <DialogTitle>Are you sure?</DialogTitle>
                <DialogActions>
                  <Button onClick={()=>setOpen({openDetails:true})}>Cancel</Button>
                  <Button onClick={handleDeleteDetail}>Delete</Button>
                </DialogActions>
              </Dialog>

              

            </main>
        );
}








const mapStateToProps = (state) => {
  return {
      user:firebase.auth().currentUser
  }
}
export default connect(mapStateToProps)(View);